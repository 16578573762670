import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';
import {Application} from './models/Application';
import {ApplicationComponent} from './models/ApplicationComponent';
import {ApplicationNote} from './models/ApplicationNote';
import {ServiceContract} from './models/ServiceContract';
import {Country} from './models/Country';
import {Region} from './models/Region';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  public get apiEndpoint() {
    //return 'https://coapme.code-lake.com/api/';
    return 'http://localhost:8000/api/';
  }

  public get cloudEndpoint() {
    return 'https://coapme.code-lake.com/';
  }

  // Authentication

  public login(email: string, password: string): boolean {
    this.http
      .post(this.apiEndpoint + 'login', {'email': email, 'password': password})
      .subscribe((response: any) => {
        if (!response.access_token) {
          console.log('Login failed!');
          return false;
        } else {
          this.authService.authenticationToken = response.access_token;
          this.authService.user = response.user;
          this.router.navigateByUrl('');
          console.log(this.authService.authenticationToken);
          return true;
        }
      });
    return true;
  }

  public register(firstname: string, lastname: string, email: string, password: string): Observable<any> {
    return this.http
      .post(this.apiEndpoint + 'register', {
        'firstname': firstname,
        'lastname': lastname,
        'email': email,
        'password': password,
        'password_confirmation': password,
      });
  }

  public get httpOptions() {
    const httpOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authService.authenticationToken,
      }
    };
    return httpOptions;
  }

  // End of authentication

  public apiRequest<T = any>(method: 'get' | 'post', resourcePath: string, options: { payLoad?: any, headers?: {[key:string]:string} } = {}): Observable<T> {
    options.headers = Object.assign({}, this.httpOptions.headers, options.headers || {});
    switch (method) {
      case 'get': return this.http.get(this.apiEndpoint + resourcePath, options).pipe(map((x: any) => x.data));
      case 'post': return this.http.post(this.apiEndpoint + resourcePath, options.payLoad, options).pipe(map((x: any) => x.data));
      default: throw new Error('Unexpected method!');
    }
  }

  /**
   * Retrieves a all users from API
   *
   * @returns An observable object
   */
  public getAllUsers(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'users', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getUserById(userId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'users/' + userId, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllApplications(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getApplicationById(applicationId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/' + applicationId, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public storeApplication(application: Application): Observable<any> {
    return this.http
      .post<any>(this.apiEndpoint + 'applications/create', application, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public updateApplication(application: Application) {
    return this.http
      .post<any>(this.apiEndpoint + 'applications/' + application.id, application, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getComponentsOfApplication(applicationId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/' + applicationId + '/components', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getComponentOfApplication(applicationId: number, componentId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/' + applicationId + '/components/' + componentId, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public storeComponent(applicationId: number, component: ApplicationComponent): Observable<any> {
    return this.http
      .post<any>(this.apiEndpoint + 'applications/' + applicationId + '/components/create', component, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public storeApplicationNote(applicationNote: ApplicationNote): Observable<any> {
    return this.http
      .post<any>(this.apiEndpoint + 'applications/' + applicationNote.application.id + '/notes/create', applicationNote, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public approveStep(componentId: number, stepId: number): Observable<any> {
    return this.http
      .post<any>(this.apiEndpoint + 'components/' + componentId + '/procurementsteps/' + stepId + '/approve', {}, this.httpOptions);
  }

  public reopenStep(componentId: number, stepId: number): Observable<any> {
    return this.http
      .post<any>(this.apiEndpoint + 'components/' + componentId + '/procurementsteps/' + stepId + '/reopen', {}, this.httpOptions);
  }

  public getNotesOfApplication(applicationId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/' + applicationId + '/notes', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getCountriesOfApplications(applicationId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/' + applicationId + '/countries', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public removeCountryOfApplication(applicationId: number, country: Country): Observable<any> {
    return this.http
      .post(this.apiEndpoint + 'applications/' + applicationId + '/delete-country', country, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public addCountriesToApplication(applicationId: number, countries: Country[]): Observable<any> {
    return this.http
      .post(this.apiEndpoint + 'applications/' + applicationId + '/countries', countries, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getRegionsOfApplications(applicationId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/' + applicationId + '/regions', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public removeRegionOfApplication(applicationId: number, region: Region): Observable<any> {
    return this.http
      .post(this.apiEndpoint + 'applications/' + applicationId + '/delete-region', region, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public addRegionsToApplication(applicationId: number, regions: Region[]): Observable<any> {
    return this.http
      .post(this.apiEndpoint + 'applications/' + applicationId + '/regions', regions, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllProjects(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'projects', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllStatuses(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'statuses', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllStreams(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'streams', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllObligationHolders(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'obligationholders', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllLicenseMetrics(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'licensemetrics', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllLicenseTypes(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'licensetypes', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllEnvironmentTypes(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'environmenttypes', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllCountries(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'countries', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  public getAllRegions(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'regions', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  // SERVICE CONTRACTS

  public getAllServiceContracts(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Retrieves a single service contract from API by providing the contract id
   *
   * @param contractId
   * @returns An observable object
   */
  public getServiceContractById(contractId: number): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts/' + contractId, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Posts a single contract to API for storing
   *
   * @param contract - An instance of an service contract
   * @returns An observable object
   */
  public storeServiceContract(contract: ServiceContract): Observable<any> {
    return this.http
      .post<any>(this.apiEndpoint + 'servicecontracts/create', contract, this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Gets all service contract priorities from API
   *
   * @returns An observable object
   */
  public getAllServiceContractPriorities(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts-priorities', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Gets all service contract categories from API
   *
   * @returns An observable object
   */
  public getAllServiceContractCategories(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts-categories', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Gets all service contract statuses from API
   *
   * @returns An observable object
   */
  public getAllServiceContractStatuses(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts-statuses', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Gets all service contract third party possible options from API
   *
   * @returns An observable object
   */
  public getAllServiceContractThirdPartyPossibleOptions(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts-thirdpartypossibleoptions', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  /**
   * Gets all service contract transferability possible options from API
   *
   * @returns An observable object
   */
  public getAllServiceContractTransferabilityPossibleOptions(): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'servicecontracts-transferabilitypossibleoptions', this.httpOptions)
      .pipe(map((x: any) => x.data));
  }

  // STATISTICS

  public getTotalApplications() {
    return this.http
      .get<any>(this.apiEndpoint + 'total-applications', this.httpOptions);
  }

  public getTotalComponents() {
    return this.http
      .get<any>(this.apiEndpoint + 'total-components', this.httpOptions);
  }

  public getTotalServiceContracts() {
    return this.http
      .get<any>(this.apiEndpoint + 'total-service-contracts', this.httpOptions);
  }

  public getTotalApplicationsInScope() {
    return this.http
      .get<any>(this.apiEndpoint + 'applications-in-scope', this.httpOptions);
  }

  public getTotalApplicationsOutOfScope() {
    return this.http
      .get<any>(this.apiEndpoint + 'applications-out-of-scope', this.httpOptions);
  }

  /**
   * Retrieves a summary of all application scopes for one stream
   *
   * @param streamId
   * @returns An observable object
   */
  public getAppsScopePerStream(streamId): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'streams/' + streamId + '/scopecount');
  }

  /**
   * Retrieves a summary of all application statuses for one stream
   *
   * @param streamId
   * @returns An observable object
   */
  public getAppsPerStream(streamId): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'streams/' + streamId + '/count');
  }

  /**
   * Retrieves a summary of all application obligation holders for one stream
   *
   * @param streamId
   * @returns An observable object
   */
  public getAppsObligationHoldersPerStream(streamId): Observable<any> {
    return this.http
      .get<any>(this.apiEndpoint + 'streams/' + streamId + '/obligationholdercount');
  }

  // Excel Export

  public exportApplications() {
    return this.http
      .get<any>(this.apiEndpoint + 'applications/export', this.httpOptions);
  }
}

import {createEmptyRank, Rank} from './Rank';

export interface User {
  id: number;
  created_at: string;
  updated_at: string;
  lastname: string;
  firstname: string;
  email: string;
  profile_picture: string;
  rank: Rank;
}

export const createEmptyUser = (): User => ({
  id: 0,
  created_at: null,
  updated_at: null,
  lastname: null,
  firstname: null,
  email: null,
  profile_picture: null,
  rank: createEmptyRank(),
});

import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {map} from 'rxjs/operators';
import {Application} from '../models/Application';
import {Status} from '../models/Status';
import {Subscription} from 'rxjs';
import {Stream} from '../models/Stream';
import {SnackbarService} from '../snackbar.service';
import {ObligationHolder} from '../models/ObligationHolder';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-application-edit',
  templateUrl: './application-edit.component.html',
  styleUrls: ['./application-edit.component.css'],
})
export class ApplicationEditComponent implements OnInit {

  private routeSub: Subscription;
  public application: Application;
  public statuses: Status[];
  public statusesLoaded = false;
  public streams: Stream[];
  public streamsLoaded = false;
  public obligationHolders: ObligationHolder[];
  public obligationHoldersLoaded = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
  ) {
  }

  ngOnInit(): void {

    this.routeSub = this.route.params.subscribe(params => {

      // load application
      this.api.getApplicationById(params['id']).subscribe((application: Application) => {
        this.application = application;

        // load statuses
        this.api.getAllProjects().subscribe((statuses: Status[]) => {
          this.statuses = statuses;
          this.statusesLoaded = true;
        });

        // load streams
        this.api.getAllStreams().subscribe((streams: Stream[]) => {
          this.streams = streams;
          this.streamsLoaded = true;
        });

        // load obligation holders
        this.api.getAllObligationHolders().subscribe((obligationHolders: ObligationHolder[]) => {
          this.obligationHolders = obligationHolders;
          this.obligationHoldersLoaded = true;
        });

      });
    });
  }

  public navigateBack() {
    this.router.navigateByUrl('/applications/' + this.application.id);
  }

  public postApplication() {
    this.api.updateApplication(this.application).subscribe((application: Application) => {
      this.router.navigateByUrl('/applications/' + application.id);
      this.snackBar.pushSnackbar('Application ' + application.name + ' has been updated');
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {ApplicationComponent, createEmptyApplicationComponent} from '../models/ApplicationComponent';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Application} from '../models/Application';
import {MatTableDataSource} from '@angular/material/table';
import {Status} from '../models/Status';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {LicenseMetric} from '../models/LicenseMetric';
import {LicenseType} from '../models/LicenseType';
import {EnvironmentType} from '../models/EnvironmentType';
import {SnackbarService} from '../snackbar.service';

@Component({
  selector: 'app-component-create',
  templateUrl: './component-create.component.html',
  styleUrls: ['./component-create.component.css']
})
export class ComponentCreateComponent implements OnInit {

  public component = createEmptyApplicationComponent();
  public application: Application;
  private routeSub: Subscription;

  public licenseMetrics: LicenseMetric[];
  public licenseMetricsLoaded = false;

  public licenseTypes: LicenseType[];
  public licenseTypesLoaded = false;

  public environmentTypes: EnvironmentType[];
  public environmentTypesLoaded = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.api.getApplicationById(params['id']).subscribe((application: Application) => {
        this.application = application;
      });
    });

    this.api.getAllLicenseMetrics().subscribe((licenseMetrics: LicenseMetric[]) => {
      this.licenseMetrics = licenseMetrics;
      this.licenseMetricsLoaded = true;
    });

    this.api.getAllLicenseTypes().subscribe((licenseTypes: LicenseType[]) => {
      this.licenseTypes = licenseTypes;
      this.licenseTypesLoaded = true;
    });

    this.api.getAllEnvironmentTypes().subscribe((environmentTypes: EnvironmentType[]) => {
      this.environmentTypes = environmentTypes;
      this.environmentTypesLoaded = true;
    });

  }

  public postComponent() {
    this.api.storeComponent(this.application.id, this.component).subscribe((component: ApplicationComponent) => {
      this.router.navigateByUrl('/applications/' + this.application.id);
      this.snackBar.pushSnackbar('Component has been created');
    });
  }

  public navigateBack() {
    this.router.navigateByUrl('/applications/' + this.application.id);
  }

}

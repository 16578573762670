export interface ContractStatus {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export const createEmptyContractStatus = (): ContractStatus => ({
  id: null,
  created_at: '',
  updated_at: '',
  name: '',
});

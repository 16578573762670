import { Component, OnInit } from '@angular/core';
import {User} from '../models/User';
import {ApiService} from '../api.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit {

  private routeSub: Subscription;
  public user: User;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.api.apiRequest<User>('get', 'users/' + params['id']).subscribe(user => {
        this.user = user;
        console.log(this.user);
      });
    });
  }

}

export interface Status {
    id: number;
    created_at: string;
    updated_at: string;
    index: number;
    name: string;
}

export const createEmptyStatus = (): Status => ({
    id: 1,
    created_at: '',
    updated_at: '',
    index: 1,
    name: '',
});

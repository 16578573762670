import {ContractPriority, createEmptyContractPriority} from './ContractPriority';
import {ContractCategory, createEmptyContractCategory} from './ContractCategory';
import {ContractStatus, createEmptyContractStatus} from './ContractStatus';
import {createEmptyObligationHolder, ObligationHolder} from './ObligationHolder';
import {createEmptyThirdPartyPossibleOption, ThirdPartyPossibleOption} from './ThirdPartyPossibleOption';
import {createEmptyTransferabilityPossibleOption, TransferabilityPossibleOption} from './TransferabilityPossibleOption';

export interface ServiceContract {
  id: number;
  created_at: string;
  updated_at: string;
  internalContractId: number;
  legalEntity: string;
  dedicated: string;
  contractPriority: ContractPriority;
  contractCategory: ContractCategory;
  contractStatus: ContractStatus;
  obligationHolder: ObligationHolder;
  vendor: string;
  product: string;
  description: string;
  thirdPartyPossible: ThirdPartyPossibleOption;
  thirdPartyUsageRights: string;
  transferabilityPossible: TransferabilityPossibleOption;
  transferabilityRights: string;
  oneTimeCost: number;
  runCost: number;
  terminationDate: string;
  comments: string;
}

export const createEmptyServiceContract = (): ServiceContract => ({
  id: null,
  created_at: '',
  updated_at: '',
  internalContractId: null,
  legalEntity: '',
  dedicated: '',
  contractPriority: createEmptyContractPriority(),
  contractCategory: createEmptyContractCategory(),
  contractStatus: createEmptyContractStatus(),
  obligationHolder: createEmptyObligationHolder(),
  vendor: '',
  product: '',
  description: '',
  thirdPartyPossible: createEmptyThirdPartyPossibleOption(),
  thirdPartyUsageRights: '',
  transferabilityPossible: createEmptyTransferabilityPossibleOption(),
  transferabilityRights: '',
  oneTimeCost: 0,
  runCost: 0,
  terminationDate: '',
  comments: '',
});

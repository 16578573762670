import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from "@angular/material/button";
import {ApplicationViewComponent} from './application-view/application-view.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import { ApplicationListComponent } from './application-list/application-list.component';
import {HttpClientModule} from "@angular/common/http";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatCardModule} from "@angular/material/card";
import {MatChipsModule} from "@angular/material/chips";
import { StreamListComponent } from './stream-list/stream-list.component';
import { ApplicationStatusDialogComponent } from './dialogs/application-status-dialog/application-status-dialog.component';
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import { SnackBarBottomRightComponent } from './snackbars/snack-bar-bottom-right/snack-bar-bottom-right.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { ProjectListComponent } from './project-list/project-list.component';
import { ComponentViewComponent } from './component-view/component-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComponentCreateComponent } from './component-create/component-create.component';
import { ApplicationEditComponent } from './application-edit/application-edit.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import { ApplicationShareLinkDialogComponent } from './dialogs/application-share-link-dialog/application-share-link-dialog.component';
import { ApplicationCreateComponent } from './application-create/application-create.component';
import { LoginComponent } from './auth/login/login.component';
import {MatTableFilterModule} from "mat-table-filter";
import {NgApexchartsModule} from 'ng-apexcharts';
import {AuthGuard} from './auth.guard';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ApplicationNoteCreateComponent } from './application-note-create/application-note-create.component';
import { ServicecontractListComponent } from './servicecontract-list/servicecontract-list.component';
import { ServicecontractViewComponent } from './servicecontract-view/servicecontract-view.component';
import { ServicecontractCreateComponent } from './servicecontract-create/servicecontract-create.component';
import { ServicecontractEditComponent } from './servicecontract-edit/servicecontract-edit.component';
import { RegisterComponent } from './auth/register/register.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ApplicationCountryDialogComponent } from './dialogs/application-country-dialog/application-country-dialog.component';
import { ApplicationRegionDialogComponent } from './dialogs/application-region-dialog/application-region-dialog.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserViewComponent } from './user-view/user-view.component';
import {TimeAgoPipe} from 'time-ago-pipe';

const routes: Routes = [
    {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'register', component: RegisterComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'projects', component: ProjectListComponent, canActivate: [AuthGuard]},
    {path: 'applications', component: ApplicationListComponent, canActivate: [AuthGuard]},
    {path: 'applications/create', component: ApplicationCreateComponent, canActivate: [AuthGuard]},
    {path: 'applications/:id', component: ApplicationViewComponent, canActivate: [AuthGuard]},
    {path: 'applications/:id/components/create', component: ComponentCreateComponent, canActivate: [AuthGuard]},
    {path: 'applications/:id/components/:component_id', component: ComponentViewComponent, canActivate: [AuthGuard]},
    {path: 'applications/:id/edit', component: ApplicationEditComponent, canActivate: [AuthGuard]},
    {path: 'applications/:id/notes/create', component: ApplicationNoteCreateComponent, canActivate: [AuthGuard]},
    {path: 'streams', component: StreamListComponent, canActivate: [AuthGuard]},
    {path: 'servicecontracts', component: ServicecontractListComponent, canActivate: [AuthGuard]},
    {path: 'servicecontracts/create', component: ServicecontractCreateComponent, canActivate: [AuthGuard]},
    {path: 'servicecontracts/:id', component: ServicecontractViewComponent, canActivate: [AuthGuard]},
    {path: 'servicecontracts/:id/edit', component: ServicecontractEditComponent, canActivate: [AuthGuard]},
    {path: 'users', component: UserListComponent, canActivate: [AuthGuard]},
    {path: 'users/:id', component: UserViewComponent, canActivate: [AuthGuard]},
];

@NgModule({
    declarations: [
        AppComponent,
        ApplicationViewComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        ApplicationListComponent,
        StreamListComponent,
        ApplicationStatusDialogComponent,
        SnackBarBottomRightComponent,
        ProjectListComponent,
        ComponentViewComponent,
        DashboardComponent,
        ComponentCreateComponent,
        ApplicationEditComponent,
        ApplicationShareLinkDialogComponent,
        ApplicationCreateComponent,
        LoginComponent,
        ApplicationNoteCreateComponent,
        ServicecontractListComponent,
        ServicecontractViewComponent,
        ServicecontractCreateComponent,
        RegisterComponent,
        LogoutComponent,
        ApplicationCountryDialogComponent,
        ApplicationRegionDialogComponent,
        UserListComponent,
        UserViewComponent,
        TimeAgoPipe,
    ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatGridListModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatProgressBarModule,
    MatSortModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatSelectModule,
    MatSnackBarModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatTableFilterModule,
    NgApexchartsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    HttpClientModule
  ],
    exports: [
      RouterModule,
      MatSidenavModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../api.service";
import {Stream} from '../models/Stream';

@Component({
  selector: 'app-stream-list',
  templateUrl: './stream-list.component.html',
  styleUrls: ['./stream-list.component.css']
})
export class StreamListComponent implements OnInit {

  private routeSub: Subscription;
  public streamsLoaded = false;
  public streams: Stream[];

  Colors: Array<any> = ["first", "second", "third", "fourth", "fifth", "sixth"];

  constructor(
      private http: HttpClient,
      private route: ActivatedRoute,
      private router: Router,
      private api: ApiService
  ) { }

  ngOnInit(): void {
    this.api.getAllStreams().subscribe((streams: Stream[]) => {
      this.streams = streams;
      this.streamsLoaded = true;
    });
  }

  public getColors(index: number) {
    let num = this.getnumber(index);
    return this.Colors[num];
  }

  getnumber(data) {
    let i = data;
    if (i > this.Colors.length - 1) {

      i = i - this.Colors.length;
      if (i < this.Colors.length) {
        return i;
      } else {
        this.getnumber(i);
      }

    } else {
      return i;
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../api.service";
import {Project} from '../models/Project';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  public projectsLoaded = false;
  public projects: Project[];

  Colors: Array<any> = ["first", "second", "third"];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.api.getAllProjects().subscribe((projects: Project[]) => {
      this.projectsLoaded = true;
      this.projects = projects;
    });
  }

  public getColors(index: number) {
    let num = this.getnumber(index);
    return this.Colors[num];
  }

  getnumber(data) {
    let i = data;
    if (i > this.Colors.length - 1) {

      i = i - this.Colors.length;
      if (i < this.Colors.length) {
        return i;
      } else {
        this.getnumber(i);
      }

    } else {
      return i;
    }
  }

}

import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {Subscription} from 'rxjs';
import {Status} from '../../models/Status';
import {Application} from '../../models/Application';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../snackbar.service';

@Component({
  selector: 'app-application-status-dialog',
  templateUrl: './application-status-dialog.component.html',
  styleUrls: ['./application-status-dialog.component.css'],
})
export class ApplicationStatusDialogComponent implements OnInit {

  public statuses: Status[];
  public statusesLoaded = true;
  public application: Application;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
    public dialogRef: MatDialogRef<ApplicationStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit(): void {
  }

  public updatePreDay1Status(preDay1Status: Status) {
    console.log(preDay1Status);
    this.data.application.preDay1Status = preDay1Status;
  }

  public updatePostDay1Status(postDay1Status: Status) {
    console.log(postDay1Status);
    this.data.application.postDay1Status = postDay1Status;
  }

  public updateStatuses() {
    // TODO use seperate api route and implement smaller update funciton in backend, no need to update whole applicaiton everytime
    this.api.updateApplication(this.data.application).subscribe((application: Application) => {
      this.dialogRef.close();
      this.snackBar.pushSnackbar('Statuses have been updated');
      this.data.application.updated_at = new Date().toISOString();
    })

  }
}

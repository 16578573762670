import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {SnackbarService} from '../../snackbar.service';
import {FormControl} from '@angular/forms';
import {Country} from '../../models/Country';

@Component({
  selector: 'app-application-country-dialog',
  templateUrl: './application-country-dialog.component.html',
  styleUrls: ['./application-country-dialog.component.css']
})
export class ApplicationCountryDialogComponent implements OnInit {

  public countries = new FormControl();
  public selectedCountries: Country[];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
    public dialogRef: MatDialogRef<ApplicationCountryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  public updateCountries() {
    console.log(this.selectedCountries);
    this.api.addCountriesToApplication(this.data.application.id, this.selectedCountries).subscribe(((x: any) => {
      this.dialogRef.close();
      this.snackBar.pushSnackbar('Countries have been updated');
      this.router.navigateByUrl('applications/' + this.data.application.id);
    }));
  }

}

export interface LicenseMetric {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    description: string;
}

export const createEmptyLicenseMetric = (): LicenseMetric => ({
    id: null,
    created_at: '',
    updated_at: '',
    name: '',
    description: '',
});

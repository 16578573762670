import {Component, Inject, OnInit} from '@angular/core';
import {Application} from '../../models/Application';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-application-share-link-dialog',
  templateUrl: './application-share-link-dialog.component.html',
  styleUrls: ['./application-share-link-dialog.component.css']
})
export class ApplicationShareLinkDialogComponent implements OnInit {

  public application: Application;

  constructor(
    public api: ApiService,
    public dialogRef: MatDialogRef<ApplicationShareLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.application = this.data.application
  }

}

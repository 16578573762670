import {Component, OnInit} from '@angular/core';
import {Application} from '../models/Application';
import {MatTableDataSource} from '@angular/material/table';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ApplicationStatusDialogComponent} from '../dialogs/application-status-dialog/application-status-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Status} from '../models/Status';
import {ApplicationShareLinkDialogComponent} from '../dialogs/application-share-link-dialog/application-share-link-dialog.component';
import {ApplicationComponent} from '../models/ApplicationComponent';
import {ApplicationNote} from '../models/ApplicationNote';
import {Country} from '../models/Country';
import {ApplicationCountryDialogComponent} from '../dialogs/application-country-dialog/application-country-dialog.component';
import {Region} from '../models/Region';
import {ApplicationRegionDialogComponent} from '../dialogs/application-region-dialog/application-region-dialog.component';
import {TimeService} from '../services/time.service';

@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.css'],
})
export class ApplicationViewComponent implements OnInit {

  public application: Application;
  private routeSub: Subscription;
  public componentsLoaded = false;
  public notesLoaded = false;

  public countries: Country[];
  public countriesLoaded = false;
  public allCountries: Country[];

  public regions: Region[];
  public regionsLoaded = false;
  public allRegions: Region[];

  public statuses: Status[];

  public refreshing = false;

  // Shareable utils
  private clientName = 'ey';
  public shareEmailString: string;

  // Components Table
  displayedColumns: string[] = ['vendor', 'productName', 'licenseMetric', 'licenseType', 'quantity', 'procurementSteps', 'dpaStatus'];
  dataSource = new MatTableDataSource();

  // Notes Table
  noteColumns: string[] = ['created_at', 'author', 'note'];
  noteDataSource = new MatTableDataSource();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    public dialog: MatDialog,
  ) {
  }

  public getPreDay1Style() {
    return this.application.preDay1Status.name.replace(/[/ ]/g, '').toLowerCase();
  }

  public getPostDay1Style() {
    return this.application.postDay1Status.name.replace(/[/ ]/g, '').toLowerCase();
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {

      // load application
      this.api.getApplicationById(params['id']).subscribe((application: Application) => {
        this.application = application;
        // this.application.updated_at = this.timeService.getRelativeTimeFormat(this.application.updated_at);

        // load components of application
        this.api.getComponentsOfApplication(params['id']).subscribe((response: any) => {
          this.dataSource = new MatTableDataSource(response);
          this.componentsLoaded = true; // DEBUG bool for view: render table after plates loaded
        });

        // load notes of application
        this.api.getNotesOfApplication(params['id']).subscribe((notes: ApplicationNote[]) => {
          this.noteDataSource = new MatTableDataSource(notes);
          this.notesLoaded = true; // DEBUG bool for view: render table after plates loaded
        });

        // load countries of application
        this.api.getCountriesOfApplications(params['id']).subscribe((countries: Country[]) => {
          this.countries = countries;
          this.countriesLoaded = true; // DEBUG bool for view: render table after plates loaded
        });

        this.api.getRegionsOfApplications(params['id']).subscribe((regions: Region[]) => {
          this.regions = regions;
          this.regionsLoaded = true;
        });

        // load statuses
        this.api.getAllStatuses().subscribe((statuses: Status[]) => {
          this.statuses = statuses;
        });

        this.generateEmailString();

      });
    });
    this.api.getAllCountries().subscribe((countries: Country[]) => {
      this.allCountries = countries;
    });

    this.api.getAllRegions().subscribe((regions: Region[]) => {
      this.allRegions = regions;
    });

  }

  private generateEmailString() {
    this.shareEmailString = 'mailto:?subject=' + this.application.name +'%20%23' + this.application.id + '%20%7C%20SUBJECT&body=Dear%20%5BRECEIVER%5D%2C%0D%0A%0D%0Aplease%20find%20more%20details%20regarding%20the%20application%20here%3A%0D%0Ahttps%3A%2F%2F' + this.clientName + '.mergery.com%2Fapplications%2F' + this.application.id + '%0D%0A%0D%0ABest%20regards%0D%0A%5BSENDER%5D';
  }

  public openDialog(application: Application) {
    this.dialog.open(ApplicationStatusDialogComponent, {data: {application: application, statuses: this.statuses}});
  }

  public openCoutryDialog(application: Application) {
    // Filter all already applied countries from all countries so one country cannot be added multiple times
    for( var i=this.allCountries.length - 1; i>=0; i--){
      for( var j=0; j<this.countries.length; j++){
        if(this.allCountries[i] && (this.allCountries[i].name === this.countries[j].name)){
          this.allCountries.splice(i, 1);
        }
      }
    }

    let dialogRef = this.dialog.open(ApplicationCountryDialogComponent, {
      data: {
        application: application,
        countries: this.allCountries,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.api.getCountriesOfApplications(this.application.id).subscribe((countries: Country[]) => {
        this.countries = countries;
      });
    });
  }

  public openRegionDialog(application: Application) {
    // Filter all already applied regions from all regions so one region cannot be added multiple times
    for( var i=this.allRegions.length - 1; i>=0; i--){
      for( var j=0; j<this.regions.length; j++){
        if(this.allRegions[i] && (this.allRegions[i].name === this.regions[j].name)){
          this.allRegions.splice(i, 1);
        }
      }
    }

    let dialogRef = this.dialog.open(ApplicationRegionDialogComponent, {
      data: {
        application: application,
        regions: this.allRegions,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.api.getRegionsOfApplications(this.application.id).subscribe((regions: Region[]) => {
        this.regions = regions;
      });
    });
  }

  public removeCountry(country: Country) {
    this.api.removeCountryOfApplication(this.application.id, country).subscribe(((response: any) => {
      const index: number = this.countries.indexOf(country);
      if (index !== -1) {
        this.countries.splice(index, 1);
        this.allCountries.push(country);
        this.allCountries.sort((a, b) => a.name.localeCompare(b.name));
      }
    }));
  }

  public removeRegion(region: Region) {
    this.api.removeRegionOfApplication(this.application.id, region).subscribe(((response: any) => {
      const index: number = this.regions.indexOf(region);
      if (index !== -1) {
        this.regions.splice(index, 1);
        this.allRegions.push(region);
        this.allRegions.sort((a, b) => a.name.localeCompare(b.name));
      }
    }));
  }

  public navigate(element: any) {
    const componentIndex = this.dataSource.data.indexOf(element);
    let component: any;
    component = this.dataSource.data[componentIndex];
    this.router.navigateByUrl('/applications/' + this.application.id + '/components/' + component.id);
  }

  public navigateBack() {
    this.router.navigateByUrl('/applications');
  }

  public shareLink(application: Application) {
    this.dialog.open(ApplicationShareLinkDialogComponent, {data: {application: application}});
  }

  public refresh() {
    this.refreshing = true;
    this.api.getApplicationById(this.application.id).subscribe(((application: Application) => {
      this.application = application;
      this.refreshing = false;
    }));
  }

}

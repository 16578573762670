import { Component, OnInit } from '@angular/core';
import {createEmptyUser, User} from '../../models/User';
import {ApiService} from '../../api.service';
import {AuthService} from '../../auth.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public firstname: string;
  public lastname: string;
  public password: string;
  public password_confirmation: string;
  public email: string;
  public loading = false;
  public passwordsDoNotMatch = false;
  public missing = false;

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private http: HttpClient,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.firstname = null;
    this.lastname = null;
    this.password = null;
    this.email = null;
  }

  public register() {
    if (this.email !== 'null' && this.password !== null && this.firstname !== null && this.lastname !== null) {
      this.missing = false;
      if (this.password !== this.password_confirmation) {
        this.passwordsDoNotMatch = true;
      } else {
        this.loading = true;
        this.api.register(this.firstname, this.lastname, this.email, this.password).subscribe((response: any) => {
          this.auth.user = response.user;
          this.auth.authenticationToken = response.access_token;
          console.log('User from auth Service:' + this.auth.user.firstname);
          console.log('User from auth Service:' + this.auth.user.lastname);
          console.log('User from auth Service:' + this.auth.user.email);
          console.log('User from auth Service:' + this.auth.user.created_at);
          console.log('Token from auth Service:' + this.auth.authenticationToken);
          this.router.navigateByUrl('');
          this.loading = false;
        });
      }
    } else {
      this.missing = true;
    }

  }

}

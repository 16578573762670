export interface Rank {
  id: number;
  name: string;
  shortcut: string;
}

export const createEmptyRank = (): Rank => ({
  id: null,
  name: '',
  shortcut: '',
});

import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Region} from '../../models/Region';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../api.service';
import {SnackbarService} from '../../snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-application-region-dialog',
  templateUrl: './application-region-dialog.component.html',
  styleUrls: ['./application-region-dialog.component.css']
})
export class ApplicationRegionDialogComponent implements OnInit {

  public regions = new FormControl();
  public selectedRegions: Region[];

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
    public dialogRef: MatDialogRef<ApplicationRegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  public updateRegions() {
    this.api.addRegionsToApplication(this.data.application.id, this.selectedRegions).subscribe(((x: any) => {
      this.dialogRef.close();
      this.snackBar.pushSnackbar('Regions have been updated');
      this.router.navigateByUrl('applications/' + this.data.application.id);
    }));
  }
}

import { Component, OnInit } from '@angular/core';
import {createEmptyApplication} from '../models/Application';
import {Status} from '../models/Status';
import {Stream} from '../models/Stream';
import {Project} from '../models/Project';
import {ObligationHolder} from '../models/ObligationHolder';
import {createEmptyServiceContract, ServiceContract} from '../models/ServiceContract';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../api.service';
import {SnackbarService} from '../snackbar.service';
import {ContractPriority} from '../models/ContractPriority';
import {ContractCategory} from '../models/ContractCategory';
import {ContractStatus} from '../models/ContractStatus';
import {ThirdPartyPossibleOption} from '../models/ThirdPartyPossibleOption';
import {TransferabilityPossibleOption} from '../models/TransferabilityPossibleOption';

@Component({
  selector: 'app-servicecontract-create',
  templateUrl: './servicecontract-create.component.html',
  styleUrls: ['./servicecontract-create.component.css']
})
export class ServicecontractCreateComponent implements OnInit {

  public servicecontract = createEmptyServiceContract();
  // TODO implement project handling

  public contractPriorities: ContractPriority[];
  public contractPrioritiesLoaded = false;

  public contractCategories: ContractCategory[];
  public contractCategoriesLoaded = false;

  public contractStatuses: ContractStatus[];
  public contractStatusesLoaded =  false;

  public obligationHolders: ObligationHolder[];
  public obligationHoldersLoaded = false;

  public thirdPartyPossibleOptions: ThirdPartyPossibleOption[];
  public thirdPartyPossibleOptionsLoaded = false;

  public transferabilityPossibleOptions: TransferabilityPossibleOption[];
  public transferabilityPossibleOptionsLoaded = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.api.getAllServiceContractPriorities().subscribe((contractPriorities: ContractPriority[]) => {
      this.contractPriorities = contractPriorities;
      this.contractPrioritiesLoaded = true;
    });

    this.api.getAllServiceContractCategories().subscribe((contractCategories: ContractCategory[]) => {
      this.contractCategories = contractCategories;
      this.contractCategoriesLoaded = true;
    });

    this.api.getAllServiceContractStatuses().subscribe((contractStatuses: ContractStatus[]) => {
      this.contractStatuses = contractStatuses;
      this.contractStatusesLoaded = true;
    });

    this.api.getAllObligationHolders().subscribe((obligationHolders: ObligationHolder[]) => {
      this.obligationHolders = obligationHolders;
      this.obligationHoldersLoaded = true;
    });

    this.api.getAllServiceContractThirdPartyPossibleOptions().subscribe((thirdPartyPossibleOptions: ThirdPartyPossibleOption[]) => {
      this.thirdPartyPossibleOptions = thirdPartyPossibleOptions;
      this.thirdPartyPossibleOptionsLoaded = true;
    });

    this.api.getAllServiceContractTransferabilityPossibleOptions().subscribe((transferabilityPossibleOptions: TransferabilityPossibleOption[]) => {
      this.transferabilityPossibleOptions = transferabilityPossibleOptions;
      this.transferabilityPossibleOptionsLoaded = true;
    });

  }

  public postServiceContract() {
    this.api.storeServiceContract(this.servicecontract).subscribe((serviceContract: ServiceContract) => {
      console.log(serviceContract);
      this.router.navigateByUrl('/servicecontracts/' + serviceContract.id);
    })
  }

  public navigateBack() {
    this.router.navigateByUrl('/servicecontracts');
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';

import {
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexResponsive,
  ApexTitleSubtitle,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';
import {HttpClient} from '@angular/common/http';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  colors: string[];
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};

export type PieChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  public totalApplications: number;
  public totalComponents: number;
  public totalApplicationsInScope: number;
  public totalApplicationsOutOfScope: number;
  public totalServiceContracts: number;

  // Charts
  @ViewChild('chart') chart: ChartComponent;
  public coScopeOfApps: Partial<ChartOptions>;
  public coStatusOfApps: Partial<ChartOptions>;
  public coObligationHolderOfApps: Partial<ChartOptions>;
  public coLicenseTypeDistribution: Partial<PieChartOptions>;
  public statusOfAppsSeries = false;
  public scopeOfAppsSeries = false;
  public obligationHoldersOfAppsSeries = false;
  public licenseTypeDistributionSeries = false;

  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.api.getTotalApplications().subscribe((totalApplications: number) => {
      this.totalApplications = totalApplications;
    });
    this.api.getTotalComponents().subscribe((totalComponents: number) => {
      this.totalComponents = totalComponents;
    });
    this.api.getTotalServiceContracts().subscribe((totalServiceContracts: number) => {
      this.totalServiceContracts = totalServiceContracts;
    });
    this.api.getTotalApplicationsInScope().subscribe((totalApplicationsInScope) => {
      this.totalApplicationsInScope = totalApplicationsInScope;
    });
    this.api.getTotalApplicationsOutOfScope().subscribe((totalApplicationsOutofScope) => {
      this.totalApplicationsOutOfScope = totalApplicationsOutofScope;
    });

    this.http.get(this.api.apiEndpoint + 'getAppsCountPerStream', {headers: {'Accept': 'application/json'}}).subscribe((response: {[key: string]:{[key:string]:number}}) => {
      const statusNames = Object.keys(response);
      const series = statusNames.map(name => ({ name, data: Object.values(response[name]) }));
      const firstStatusName = statusNames[0];
      const streamNameTotals = {};
      for (const statusName in response) {
        for (const streamName in response[statusName]) {
          if (!streamNameTotals[streamName]) {
            streamNameTotals[streamName] = 0;
          }
          streamNameTotals[streamName] += response[statusName][streamName];
        }
      }
      const categories = Object.keys(streamNameTotals).map(x => x + ' (' + streamNameTotals[x] + ')');
      this.initStatusOfApps(series, categories);
    });

    this.http.get(this.api.apiEndpoint + 'getAppsScopePerStream', {headers: {'Accept': 'application/json'}})
      .subscribe((response: any) => {
        console.log(response);
        const streams = response.map(x => x.stream);
        const series = [
          {
            name: 'In Scope',
            data: response.map(x => x.inScope)
          },
          {
            name: 'Out of Scope',
            data: response.map(x => x.outOfScope)
          },
        ];

        this.initScopeOfApps(series, streams);
      });

    this.http.get(this.api.apiEndpoint + 'getAppsObligationHolderPerStream', {headers: {'Accept': 'application/json'}}).subscribe((response: {[key: string]:{[key:string]:number}}) => {
      const ohNames = Object.keys(response);
      const series = ohNames.map(name => ({ name, data: Object.values(response[name]) }));
      const firstStatusName = ohNames[0];
      const streamNameTotals = {};
      for (const obligationHolder in response) {
        for (const streamName in response[obligationHolder]) {
          if (!streamNameTotals[streamName]) {
            streamNameTotals[streamName] = 0;
          }
          streamNameTotals[streamName] += response[obligationHolder][streamName];
        }
      }
      const categories = Object.keys(streamNameTotals).map(x => x + ' (' + streamNameTotals[x] + ')');
      this.initObligationHolderOfApps(series, categories);
    });

    this.http.get(this.api.apiEndpoint + 'licensetype-distribution').subscribe((response: any) => {
      const series = response.map(x => x.count);
      const labels = response.map(x => x.name);
      this.initLicenseTypeDistribution(series, labels);
    });

  }

  public initScopeOfApps(series: any, categories: any) {
    this.coScopeOfApps = {
      series,
      colors: [
        '#007dff',
        '#707070',
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: 'Scope of Applications per Stream',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          color: '#2d2d2d',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'category',
        categories,
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    };
    this.scopeOfAppsSeries = true;
  }

  public initStatusOfApps(series: any, categories: any) {
    this.coStatusOfApps = {
      series,
      colors: [
        '#3d3d3d',
        '#00B800',
        '#FFBF00',
        '#FF4B4B',
        '#8B0000',
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: 'Status of Applications per Stream',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          color: '#2d2d2d',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'category',
        categories
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    };

    this.statusOfAppsSeries = true;
  }

  public initObligationHolderOfApps(series: any, categories: any) {
    this.coObligationHolderOfApps = {
      series,
      colors: [
        '#8B0000',
        '#007dff',
        '#707070',
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: 'Obligation Holder of Applications per Stream',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          color: '#2d2d2d',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: 'category',
        categories,
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    };

    this.obligationHoldersOfAppsSeries = true;
  }

  public initLicenseTypeDistribution(series, labels) {
    this.coLicenseTypeDistribution = {
      series,
      chart: {
        width: 380,
        type: "pie"
      },
      labels,
      title: {
        text: 'License Type Distribution',
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          color: '#2d2d2d',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
    this.licenseTypeDistributionSeries = true;
  }
}

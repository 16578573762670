export interface Stream {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    streamLead: string;
    streamLeadEmail: string;
}

export const createEmptyStream = (): Stream => ({
    id: null,
    created_at: '',
    updated_at: '',
    name: '',
    streamLead: '',
    streamLeadEmail: '',
});

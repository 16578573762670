import {createEmptyUser, User} from "./User";
import {Application, createEmptyApplication} from './Application';

export interface ApplicationNote {
    id: number;
    created_at: string;
    updated_at: string;
    note: string;
    author: User;
    application: Application;
}

export const createEmptyApplicationNote = (): ApplicationNote => ({
    id: 0,
    created_at: '',
    updated_at: '',
    note: '',
    author: createEmptyUser(),
    application: createEmptyApplication(),
});

import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from '../api.service';
import {Application, createEmptyApplication} from '../models/Application';
import {Status} from '../models/Status';
import {Stream} from '../models/Stream';
import {Project} from '../models/Project';
import {SnackbarService} from '../snackbar.service';
import {ObligationHolder} from "../models/ObligationHolder";

@Component({
  selector: 'app-application-create',
  templateUrl: './application-create.component.html',
  styleUrls: ['./application-create.component.css']
})
export class ApplicationCreateComponent implements OnInit {

  public application = createEmptyApplication();
  public statuses: Status[];
  public statusesLoaded = false;
  public streams: Stream[];
  public streamsLoaded = false;
  public projects: Project[];
  public projectsLoaded = false;
  public obligationHolders: ObligationHolder[];
  public obligationHoldersLoaded = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
  ) { }

  ngOnInit(): void {

    this.api.getAllProjects().subscribe((projects: Project[]) => {
      this.projects = projects;
      this.projectsLoaded = true;
    });

    this.api.getAllProjects().subscribe((statuses: Status[]) => {
      this.statuses = statuses;
      this.statusesLoaded = true;
    });

    this.api.getAllStreams().subscribe((streams: Stream[]) => {
      this.streams = streams;
      this.streamsLoaded = true;
    });

    this.api.getAllObligationHolders().subscribe((obligationHolders: ObligationHolder[]) => {
      this.obligationHolders = obligationHolders;
      this.obligationHoldersLoaded = true;
    });

  }

  public navigateBack() {
    this.router.navigateByUrl('/applications');
  }

  public postApplication() {
    this.api.storeApplication(this.application).subscribe((application: Application) => {
      this.router.navigateByUrl('/applications/' + application.id);
      this.snackBar.pushSnackbar('Application ' + application.name + ' has been created');
    });
  }

}

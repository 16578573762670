import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';
import {Subscription} from 'rxjs';
import {Application} from "../models/Application";
import {Stream} from "../models/Stream";
import {MatTableFilter} from "mat-table-filter";

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.css'],
})
export class ApplicationListComponent implements OnInit {

  public applicationsLoaded = false;
  public applications: Application[];
  public streams: Stream[];

  displayedColumns: string[] = ['far_id', 'name', 'stream', 'applicationManager', 'technicalLead', 'preDay1Status', 'postDay1Status'];
  dataSource = new MatTableDataSource();

  public searchInput: string;

  // Filter
  filterEntity: Application;
  filterType: MatTableFilter;

  constructor(
    private http: HttpClient,
    private router: Router,
    private api: ApiService,
  ) {
  }

  ngOnInit(): void {

    this.api.getAllApplications().subscribe((applications: Application[]) => {
      this.dataSource = new MatTableDataSource(applications);
      this.applications = applications;
      this.applicationsLoaded = true;
    });

    this.api.getAllStreams().subscribe((streams: Stream[]) => {
      this.streams = streams;
    });

  }

  public navigate(element: any) {
    const applicationIndex = this.dataSource.data.indexOf(element);
    let application: any;
    application = this.dataSource.data[applicationIndex];
    this.router.navigateByUrl('/applications/' + application.id);
  }

  public navigateBack() {
    this.router.navigateByUrl('dashboard');
  }

  public export() {
    this.api.exportApplications().subscribe((response) => {
      console.log('Export Done!');
    });
  }

  public runSearch() {
    this.dataSource.filter = this.searchInput.trim().toLocaleLowerCase();
  }

}

import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loading = false;
  public attempt = true;
  public email: string;
  public password: string;

  constructor(
    private api: ApiService,
  ) { }

  ngOnInit(): void {
  }

  public login() {
    this.loading = true;
    this.attempt = this.api.login(this.email, this.password);

  }

}

import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ServiceContract} from '../models/ServiceContract';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-servicecontract-view',
  templateUrl: './servicecontract-view.component.html',
  styleUrls: ['./servicecontract-view.component.css']
})
export class ServicecontractViewComponent implements OnInit {

  public serviceContract: ServiceContract;
  private routeSub: Subscription;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.api.getServiceContractById(params['id']).subscribe((serviceContract: ServiceContract) => {
        this.serviceContract = serviceContract;
      })
    });
  }

  public navigateBack() {
    this.router.navigateByUrl('/servicecontracts');
  }

}

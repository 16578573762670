import {createEmptyLicenseMetric, LicenseMetric} from "./LicenseMetric";
import {createEmptyLicenseType, LicenseType} from "./LicenseType";
import {createEmptyEnvironmentType, EnvironmentType} from "./EnvironmentType";
import {createEmptyProcurementStep, ProcurementStep} from "./ProcurementStep";
import {createEmptyStatus, Status} from "./Status";

export interface ApplicationComponent {
    id: number;
    created_at: string;
    updated_at: string;
    vendor: string;
    productName: string;
    productEdition: string;
    productVersion: string;
    licenseMetric: LicenseMetric;
    licenseType: LicenseType;
    environmentType: EnvironmentType;
    quantity: number;
    procurementSteps: ProcurementStep[];
    dpaStatus: Status;
}

export const createEmptyApplicationComponent = (): ApplicationComponent => ({
    id: 0,
    created_at: '',
    updated_at: '',
    vendor: '',
    productName: '',
    productEdition: '',
    productVersion: '',
    licenseMetric: createEmptyLicenseMetric(),
    licenseType: createEmptyLicenseType(),
    environmentType: createEmptyEnvironmentType(),
    quantity: 0,
    procurementSteps: [],
    dpaStatus: createEmptyStatus()
});

import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ApplicationComponent} from "../models/ApplicationComponent";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../api.service";
import {Application} from "../models/Application";
import {ComponentDocument} from '../models/ComponentDocument';
import {ProcurementStep} from '../models/ProcurementStep';
import {map} from 'rxjs/operators';
import {Status} from "../models/Status";

@Component({
  selector: 'app-component-view',
  templateUrl: './component-view.component.html',
  styleUrls: ['./component-view.component.css']
})
export class ComponentViewComponent implements OnInit {

  private routeSub: Subscription;

  public component: ApplicationComponent;
  public componentLoaded = false;

  public application: Application
  public applicationLoaded = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {

      this.api.getComponentOfApplication(params['id'], params['component_id']).subscribe((component: ApplicationComponent) => {
        this.component = component;
        this.componentLoaded = true;
      });

      this.api.getApplicationById(params['id']).subscribe((application: Application) => {
        this.application = application;
        this.applicationLoaded = true;
      })

    });
  }

  public approveStep(step: ProcurementStep) {
    this.api.approveStep(this.component.id, step.id).subscribe((procurementStep: ProcurementStep) => {
      step.completed = true;
    });
  }

  public reopenStep(step: ProcurementStep) {
    this.api.reopenStep(this.component.id, step.id).subscribe((procurementStep: ProcurementStep) => {
      step.completed = false;
    });
  }

  public navigateBack() {
    this.routeSub = this.route.params.subscribe(params => {
      this.router.navigateByUrl('/applications/' + params['id']);
    });
  }

  public openDocument(componentDocument: ComponentDocument) {
    window.location.href = componentDocument.url;
  }

}

import {createEmptyUser, User} from './User';

export interface Project {
    id: number;
    created_at: string;
    updated_at: string;
    name: string;
    shortcut: string;
    client_id: number;
    engagementPartner: User;
    engagementManager: User;
}

export const createEmptyProject = (): Project => ({
    id: 0,
    created_at: '',
    updated_at: '',
    name: '',
    shortcut: '',
    client_id: 0,
    engagementPartner: createEmptyUser(),
    engagementManager: createEmptyUser(),
});

import {createEmptyStream, Stream} from './Stream';
import {createEmptyStatus, Status} from './Status';
import {createEmptyProject, Project} from './Project';
import {createEmptyObligationHolder, ObligationHolder} from "./ObligationHolder";

export interface Application {
  id: number;
  far_id: number;
  internal_application_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  inScope: boolean;
  obligationHolder: ObligationHolder;
  customPreDay1Status: string;
  customPostDay1Status: string;
  project: Project;
  stream: Stream;
  preDay1Status: Status;
  postDay1Status: Status;
  businessCritical: boolean;
  applicationManager: string;
  applicationManagerEmail: string;
  technicalLead: string;
  technicalLeadEmail: string;
  escalationInstance: string;
  escalationInstanceEmail: string;
}

export const createEmptyApplication = (): Application => ({
  id: 0,
  far_id: 0,
  internal_application_id: '',
  created_at: '',
  updated_at: '',
  name: '',
  inScope: false,
  obligationHolder: createEmptyObligationHolder(),
  customPreDay1Status: '',
  customPostDay1Status: '',
  project: createEmptyProject(),
  stream: createEmptyStream(),
  preDay1Status: createEmptyStatus(),
  postDay1Status: createEmptyStatus(),
  businessCritical: false,
  applicationManager: '',
  applicationManagerEmail: '',
  technicalLead: '',
  technicalLeadEmail: '',
  escalationInstance: '',
  escalationInstanceEmail: '',
});

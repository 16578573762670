import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {Application} from "../models/Application";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../api.service";
import {SnackbarService} from "../snackbar.service";
import {ApplicationNote, createEmptyApplicationNote} from '../models/ApplicationNote';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-application-note-create',
  templateUrl: './application-note-create.component.html',
  styleUrls: ['./application-note-create.component.css']
})
export class ApplicationNoteCreateComponent implements OnInit {

  private routeSub: Subscription;
  public application: Application;
  public applicationNote = createEmptyApplicationNote();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private snackBar: SnackbarService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      // load application
      this.api.getApplicationById(params['id']).subscribe((application: Application) => {
        this.application = application;
        this.applicationNote.application = application;
      });
    });

  }

  public navigateBack() {
    this.router.navigateByUrl('/applications/' + this.application.id);
  }

  public postNote() {
    this.applicationNote.author = this.authService.user;
    console.log(this.applicationNote);
    this.api.storeApplicationNote(this.applicationNote).subscribe((applicationNote: ApplicationNote) => {
      console.log(applicationNote);
      this.router.navigateByUrl('/applications/' + this.application.id);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {ServiceContract} from '../models/ServiceContract';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-servicecontract-list',
  templateUrl: './servicecontract-list.component.html',
  styleUrls: ['./servicecontract-list.component.css']
})
export class ServicecontractListComponent implements OnInit {

  private routeSub: Subscription;
  public contractsLoaded = false;
  public contracts: ServiceContract[];

  displayedColumns: string[] = ['internalContractId', 'legalEntity', 'contractPriority', 'contractStatus'];
  dataSource = new MatTableDataSource();

  public searchInput: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.api.getAllServiceContracts().subscribe((contracts: ServiceContract[]) => {
      this.dataSource = new MatTableDataSource(contracts);
      this.contracts = contracts;
      this.contractsLoaded = true;
    });
  }

  public runSearch() {
    this.dataSource.filter = this.searchInput.trim().toLocaleLowerCase();
  }

  public export() {
    // TODO implement
  }

  public navigate(element: any) {
    const serviceContractIndex = this.dataSource.data.indexOf(element);
    let serviceContract: any;
    serviceContract = this.dataSource.data[serviceContractIndex];
    this.router.navigateByUrl('/servicecontracts/' + serviceContract.id);
  }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {createEmptyUser, User} from './models/User';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  // public loggedIn = false;
  // TODO remove authKey and set loggedIn to false before going in production
  public loggedIn = true;
  private authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYmExMzFmZjY5MTNjY2JmYTk0NjAzOTkxOWViNWZiZTgzNTk0MmRhYjAwOWVhNzIyNjg2Y2Y2NjZhNjU1OGViODc2NzUxOTVhNjRmMmU5M2UiLCJpYXQiOjE2MDEzNzEwNjIsIm5iZiI6MTYwMTM3MTA2MiwiZXhwIjoxNjMyOTA3MDYyLCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.SQGJPxi_6MqGqxwnrOlUT7hfu6MHUiXOkeAQIdRyN0co1ddUKyOt_68Wgs3lEdSW_36OfUL-UsFwpopwVRM39sC_HcmH1iTCDFKSHbA6nHLfRdwT5OTdl7GzsQW4t0XV-g2Rze1jNKNPnl0dNZRqm99TyyyqLkLTZAjAGnhpTuQTOLeEK0UOB2qNSH9jHb_4FDbel3cPQYoC--B1itF_fOtZXeb1rrty17f0SLBE2_210_Vp9gBOpH9kmF7WnYhoul9kg9fwKr9sjVZeL9zF8MnS_j3PqtTbdOM46EcLqu4-4EcIxZN-XmYkuf0LkwgZxJw-9SJmY7BkHoVf_alU_s--FCggw4iz5QjxEwvlTQT2TLbvPwCaGaQv8OQ4KQGdXNjKE0TkUsAYzPPgxR2jh5mcsFSzPed50_beCN8Vk0aWT2ymp7s850HJk0W17tHJfQu8zBRjC31mOqDlxbrsJl6e4aFmOq6997DYsE27UMNV8UM7HsgjrC2C3nMPT5FnMto0nJv_-blh433oIdWKx2EA-TeEXSdTuuxsqNN2_9sfLpuaev7OZg5WSCoXmfyJr8XxgbombYnvO78ZCFn4rxtUd9jcxtZXFBptO6UgxwnvzY2SS4GgXYn7XjZZWO64CUC3j-TCOHlWptqpxsrWUjh0_EkGFQCbPqR2MEfVFnI';
  private authUser: User;

  constructor(
    private http: HttpClient,
  ) {
  }

  public get authenticationToken() {
    return this.authToken;
  }

  public set authenticationToken(authToken: string) {
    this.authToken = authToken;
    this.loggedIn = true;
  }

  public logout() {
    this.authToken = null;
    this.loggedIn = false;
  }


  public set user(user: User) {
    this.authUser = user;
  }

  public get user() {
    return this.authUser;
  }

  public isAuthenticated() {
    return this.authToken;
  }


}

import { Component, OnInit } from '@angular/core';
import {User} from '../models/User';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public usersLoaded = false;
  public users: User[];

  Colors: Array<any> = ["first", "second", "third"];

  constructor(
    private api: ApiService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.api.getAllUsers().subscribe((users: User[]) => {
      this.usersLoaded = true;
      this.users = users;
    });
  }

  public navigateToUser(user: User) {
    this.router.navigateByUrl('users/' + user.id);
  }

  public getColors(index: number) {
    let num = this.getnumber(index);
    return this.Colors[num];
  }

  getnumber(data) {
    let i = data;
    if (i > this.Colors.length - 1) {

      i = i - this.Colors.length;
      if (i < this.Colors.length) {
        return i;
      } else {
        this.getnumber(i);
      }

    } else {
      return i;
    }
  }

}
